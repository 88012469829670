import { ActionTypes } from "./actions";
import { defaultState, bindReducers } from "../../utils/genState";
import createReducer from "../../utils/reduxsauce/createReducer";

const initialState = {
  ...defaultState("groups", { metadata: undefined }, []),
  ...defaultState("group", { metadata: undefined }, {}),
};
const groupEditedSuccess = (state, draft, payload) => {
  const groups = state.groups || [];
  var groupIndex = groups.findIndex((u) => u.id === payload.id);
  draft.groups[groupIndex] = payload;
  draft.openDialog = false;
};
const createGroupSuccess = (state, draft, payload) => {
  const groups = state.groups || [];
  draft.groups = [...groups, payload];
  draft.openDialog = false;
};
const groupDeleteSuccess = (state, draft, payload) => {
  const groups = state.groups || [];
  draft.groups = groups.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};

const showDialog = (state, draft, payload) => {
  draft.openDialog = true;
};
const showDeleteDialog = (state, draft, payload) => {
  draft.openDeleteDialog = true;
};
const resetDialog = (state, draft, payload) => {
  draft.openDialog = false;
  draft.openDeleteDialog = false;
  draft.brand_errors = [];
};

const outletAssignedSuccess = (state, draft, payload) => {
  const groupOutletsList = state.groupOutletsList || [];
  draft.groupOutletsList = [...groupOutletsList, payload];
  draft.openDialog = false;
};

const outletRemoveSuccess = (state, draft, payload) => {
  const groupOutletsList = state.groupOutletsList || [];
  draft.groupOutletsList = groupOutletsList.filter((a) => a.id != payload.id);
  draft.openDeleteDialog = false;
};
export const reducer = createReducer(initialState, {
  ...bindReducers(ActionTypes, {
    action: "getAllGroups",
    stateName: "groups",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getGroupWithId",
    stateName: "group",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createGroup",
    stateName: "group",
    async: true,
    isPaginated: false,
    successCb: createGroupSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "updateGroup",
    stateName: "group",
    async: true,
    isPaginated: false,
    successCb: groupEditedSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "deleteGroup",
    stateName: "group",
    async: true,
    isPaginated: false,
    successCb: groupDeleteSuccess,
  }),
  ...bindReducers(ActionTypes, {
    action: "getGroupClients",
    stateName: "groupClients",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "createGroupClient",
    stateName: "groupClient",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createGroupClients",
    stateName: "groupClientsState",
    async: true,
    isPaginated: false,
  }),
  ///outlets
  ...bindReducers(ActionTypes, {
    action: "getNonGroupedOutlets",
    stateName: "nonGroupedOutlets",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "getGroupOutletsList",
    stateName: "groupOutletsList",
    async: true,
    isPaginated: true,
  }),
  ...bindReducers(ActionTypes, {
    action: "createGroupOutlet",
    stateName: "groupOutlet",
    async: true,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "createGroupOutlets",
    stateName: "groupOutletsCreated",
    async: true,
    isPaginated: false,
    successCb: outletAssignedSuccess,
  }),

  ...bindReducers(ActionTypes, {
    action: "removeGroupOutlet",
    stateName: "groupOutletRemoved",
    async: true,
    isPaginated: false,
    successCb: outletRemoveSuccess,
  }),

  ...bindReducers(ActionTypes, {
    action: "resetDialog",
    stateName: "resetDialog",
    async: false,
    isPaginated: false,
    successCb: resetDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDialog",
    stateName: "showDialog",
    async: false,
    isPaginated: false,
    successCb: showDialog,
  }),
  ...bindReducers(ActionTypes, {
    action: "showDeleteDialog",
    stateName: "showDeleteDialog",
    async: false,
    isPaginated: false,
    successCb: showDeleteDialog,
    isPaginated: false,
  }),
  ...bindReducers(ActionTypes, {
    action: "setGroup",
    stateName: "group",
    async: false,
    isPaginated: false,
  }),
});
