import { createActions } from "../../utils/reduxsauce";

const { Types, Creators } = createActions(
  {
    getAllPrizes: { args: ["payload"], meta: { async: true } },
    addPrize: { args: ["payload"], meta: { async: true } },
    editPrize: { args: ["payload"], meta: { async: true } },
    deletePrize: { args: ["payload"], meta: { async: true } },
    showDialog: { args: [], meta: { async: false } },
    showDeleteDialog: { args: [], meta: { async: false } },
    resetDialog: { args: [], meta: { async: false } },
  },
  {
    prefix: "@app/Prizes/",
  }
);
export const ActionTypes = Types;
export default Creators;
