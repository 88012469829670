import { ActionTypes } from "./actions";
import logic from "../../utils/genLogic";
import { Trans } from "@lingui/react";
import { push } from "connected-react-router";

const apiNamespace = "group";

const getAllGroupsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_ALL_GROUPS,
});
const getGroupWithIdLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_GROUP_WITH_ID,
});
const createGroupLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_GROUP,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/group/list"));
  },
});
const updateGroupLogic = logic(apiNamespace, {
  actionName: ActionTypes.UPDATE_GROUP,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_edited_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/group/list"));
  },
});
const deleteGroupLogic = logic(apiNamespace, {
  actionName: ActionTypes.DELETE_GROUP,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_deleted_successfully" />,
});

const getGroupClientsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_GROUP_CLIENTS,
});

const createGroupClientLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_GROUP_CLIENT,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});

const createGroupClientsLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_GROUP_CLIENTS,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  successCb: (dispatch) => {
    dispatch(push("/Group/list"));
  },
});

//outlets

const createGroupOutletLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_GROUP_OUTLET,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
});
const removeGroupOutletLogic = logic(apiNamespace, {
  actionName: ActionTypes.REMOVE_GROUP_OUTLET,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_removed_successfully" />,
});

const createGroupOutletsLogic = logic(apiNamespace, {
  actionName: ActionTypes.CREATE_GROUP_OUTLETS,
  showErrorMessage: true,
  errorMessage: <Trans id="there_is_an_error_occurred_try_again_later" />,
  showSuccessMessage: true,
  successMessage: <Trans id="item_added_successfully" />,
  // successCb: (dispatch) => {
  //   dispatch(push("/Group/list"));
  // },
});

const getGroupOutletsListLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_GROUP_OUTLETS_LIST,
});
const getNonGroupedOutletsLogic = logic(apiNamespace, {
  actionName: ActionTypes.GET_NON_GROUPED_OUTLETS,
});

export default [
  getAllGroupsLogic,
  getGroupWithIdLogic,
  createGroupLogic,
  updateGroupLogic,
  deleteGroupLogic,
  getGroupClientsLogic,
  createGroupClientLogic,
  createGroupClientsLogic,
  getNonGroupedOutletsLogic,
  createGroupOutletLogic,
  createGroupOutletsLogic,
  removeGroupOutletLogic,
  getGroupOutletsListLogic,
];
