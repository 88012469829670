export default function (/**@type {ApisauceInstance} */ api) {
  ///main group props
  const getAllGroups = (paging) => api.get("/group/search", paging);

  const getGroupWithId = (id) => api.get("/group/find/" + id);

  const createGroup = (payload) => api.post("/group/create", payload);

  const updateGroup = ({ payload, id }) => api.put(`group/${id}`, payload);

  const deleteGroup = (id) => api.delete("/group/remove/" + id);

  /// group clients
  const getGroupClients = (id) => api.get("/group/clients/", id);
  const createGroupClient = (payload) => api.post("/group/add-client", payload);
  const createGroupClients = (payload) =>
    api.post("/group/add-clients", payload);
  /// group outlets
  const getNonGroupedOutlets = (paging) => api.get("/group/non-gouped-outlets", paging);
  const createGroupOutlet = (payload) => api.post("/group/add-outlet", payload);

  const createGroupOutlets = (payload) =>
    api.post("/group/add-outlets", payload);

  const removeGroupOutlet = (payload) =>
    api.post("/group/remove-outlet", payload);

  const getGroupOutletsList = (id) => api.get("/group/group-outlets/" + id);

  return {
    group: {
      getAllGroups,
      getGroupWithId,
      createGroup,
      updateGroup,
      deleteGroup,
      getGroupClients,
      getNonGroupedOutlets,
      createGroupClient,
      createGroupClients,
      createGroupOutlet,
      createGroupOutlets,
      removeGroupOutlet,
      getGroupOutletsList,
    },
  };
}
