export default function (/**@type {ApisauceInstance} */ api) {

  
    const getAllPrizes = (paging) => api.get(`Prize/prizes`,paging);

    const addPrize = (payload) => api.post(`Prize/add-prize`, payload);

  
    const editPrize = ({ payload, id }) =>api.put(`Prize/edit-prize/${id}`, payload);
  
    const deletePrize = (id) => api.delete(`Prize/delete-prize/${id}`);

    return {
      prizes: {
        getAllPrizes,
        addPrize,
        editPrize,
        deletePrize
      },
    };
  }
  